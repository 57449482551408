import {
  combineReducers,
} from 'redux'

import admsReducer from './adms-reducer'
import smsCodeReducer from './sms-code-reducer'
import passwordReducer from './password-reducer';

// Root Reducer
const rootReducer = combineReducers({
  admsInfo: admsReducer,
  smsCodeInfo: smsCodeReducer,
  passwordInfo: passwordReducer,
})

export default rootReducer