import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects"
import {
  BIZ_SUCCESS,
  BIZ_WARN,
  NETWORK_ERROR,
  PASSWORD_CHANGE_BY_SMS_CODE,
  PASSWORD_CHANGE_BY_SMS_CODE_RESPONSE,
  PASSWORD_CHANGE_BY_ORIGIN,
  PASSWORD_CHANGE_BY_ORIGIN_RESPONSE,
} from "../constants/action-types"
import {
  changeBySmsCode,
  changeByOrigin,
} from "../apis/password-api"

export default function* passwordWatcher() {
  yield takeEvery(PASSWORD_CHANGE_BY_SMS_CODE, changeBySmsCodeWorker)
  yield takeEvery(PASSWORD_CHANGE_BY_ORIGIN, changeByOriginWorker)
}

function* changeBySmsCodeWorker(action) {
  try {
    const payload = yield call(changeBySmsCode, action.cmd)
    const {
      status,
      message,
    } = payload
    if (status === 0) {
      yield put({
        type: BIZ_SUCCESS,
        payload: "操作成功",
      })
    } else {
      yield put({
        type: BIZ_WARN,
        payload: message,
      })
    }
    yield put({
      type: PASSWORD_CHANGE_BY_SMS_CODE_RESPONSE,
      payload,
    })
  } catch (e) {
    yield put({
      type: NETWORK_ERROR,
    })
  }
}


function* changeByOriginWorker(action) {
  try {
    const payload = yield call(changeByOrigin, action.cmd)
    const {
      status,
      message,
    } = payload
    if (status === 0) {
      yield put({
        type: BIZ_SUCCESS,
        payload: "操作成功",
      })
    } else {
      yield put({
        type: BIZ_WARN,
        payload: message,
      })
    }
    yield put({
      type: PASSWORD_CHANGE_BY_ORIGIN_RESPONSE,
      payload,
    })
  } catch (e) {
    yield put({
      type: NETWORK_ERROR,
    })
  }
}