import urls from "../constants/urls"

import {
  restPost,
} from "../utils/restful"

export const send = (cmd) => {
  return restPost({
    url: urls.smsCodeSend,
    cmd,
  })
}