import React from "react"
import { Provider } from "react-redux"
import { hot } from 'react-hot-loader/root'


import store from "./store/index-login"
import RootContainer from "./containers/login-root-container"

const App = () => (

  <div style={{ marginTop: 60 }}>
    <Provider store={store}>
      <RootContainer />
    </Provider>
  </div>
)

export default hot(App)