import {
  createStore,
  applyMiddleware,
} from "redux"
import createSagaMiddleware from 'redux-saga'
import rootReducer from "../reducers/index-login"
import rootSaga from '../sagas/index-login'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer,
  applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(rootSaga)

export default store