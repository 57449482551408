import loadable from '@loadable/component'
import React from 'react'
import { HashRouter as Router, Route } from "react-router-dom"
import { connect } from "react-redux"

import Loading from '../presentational/loading'

const AdmsContainer = loadable(
  () => import('./commons/adms-container'),
  {
    fallback: <Loading />,
  }
)
const LoginForm = loadable(
  () => import('./login/login-form'),
  {
    fallback: <Loading />,
  }
)
const RegisterForm = loadable(
  () => import('./login/register-form'),
  {
    fallback: <Loading />,
  }
)
const ForgotPasswordForm = loadable(
  () => import('./login/forgot-password-form'),
  {
    fallback: <Loading />,
  }
)

class RootContainer extends React.Component {

  render() {

    return (
      <div>
        <AdmsContainer />
        <Router>
          <Route exact path="/" component={LoginForm} />
          <Route path="/register" component={RegisterForm} />
          <Route path="/forgot-password" component={ForgotPasswordForm} />
        </Router>
      </div>
    )
  }
}

export default connect()(RootContainer)