import {
  SMS_CODE_SEND_RESPONSE,
  SMS_CODE_TIME_COUNT,
} from '../constants/action-types'

const initialState = {
  isLoading: false,
  payload: null,
  timeCount: 0,
}

const smsCodeReducer = (state = initialState, action) => {

  const {
    payload,
  } = action
  switch (action.type) {
    case SMS_CODE_SEND_RESPONSE:
      {
        return {
          ...state,
          isLoading: false,
          payload,
        }
      }
    case SMS_CODE_TIME_COUNT:
      {
        return {
          ...state,
          timeCount: payload,
        }
      }
    default:
      {
        return state
      }
  }
}

export default smsCodeReducer