import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects"
import {
  BIZ_SUCCESS,
  BIZ_WARN,
  NETWORK_ERROR,
  SMS_CODE_SEND,
  SMS_CODE_SEND_RESPONSE,
  SMS_CODE_TIME_COUNT,
} from "../constants/action-types"
import {
  send,
} from "../apis/sms-code-api"

const delay = (ms) => new Promise(res => setTimeout(res, ms))

let count = 0

export default function* smsCodeWatcher() {
  yield takeEvery(SMS_CODE_SEND, sendWorker)
}

function* sendWorker(action) {
  try {
    const payload = yield call(send, action.cmd)
    const {
      status,
      message,
    } = payload
    if (status === 0) {
      yield put({
        type: BIZ_SUCCESS,
        payload: "操作成功",
      })
      count = 60
      while (count >= 0) {
        yield put({
          type: SMS_CODE_TIME_COUNT,
          payload: count,
        })
        yield delay(1000)
        if (count > 0)
          count -= 1
      }
    } else {
      yield put({
        type: BIZ_WARN,
        payload: message,
      })
    }
    yield put({
      type: SMS_CODE_SEND_RESPONSE,
      payload,
    })
  } catch (e) {
    yield put({
      type: NETWORK_ERROR,
    })
  }
}