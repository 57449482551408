import {
  all,
} from 'redux-saga/effects'

import admsWatcher from './adms-saga'
import smsCodeWatcher from './sms-code-saga'
import passwordWatcher from './password-saga'


export default function* rootSaga() {
  yield all([
    admsWatcher(),
    smsCodeWatcher(),
    passwordWatcher(),
  ])
}