// *system*
export const NETWORK_ERROR = "NETWORK_ERROR"

// *biz*
export const BIZ_FAIL = "BIZ_FAIL"
export const BIZ_INFO = "BIZ_INFO"
export const BIZ_SUCCESS = "BIZ_SUCCESS"
export const BIZ_WARN = "BIZ_WARN"

// *adms - auto dimission messages*
export const ADMS = "ADMS"

// auth
export const INIT_AUTH = "INIT_AUTH"
export const FETCH_SECURITY_USER = "FETCH_SECURITY_USER"
export const FETCH_SECURITY_USER_RESPONSE = "FETCH_SECURITY_USER_RESPONSE"

// me
export const ME_QUERY = "ME_QUERY"
export const ME_QUERY_RESPONSE = "ME_QUERY_RESPONSE"
export const ME_UPDATE = "ME_UPDATE"
export const ME_UPDATE_RESPONSE = "ME_UPDATE_RESPONSE"

// password
export const PASSWORD_CHANGE_BY_SMS_CODE = "PASSWORD_CHANGE_BY_SMS_CODE"
export const PASSWORD_CHANGE_BY_SMS_CODE_RESPONSE = "PASSWORD_CHANGE_BY_SMS_CODE_RESPONSE"
export const PASSWORD_CHANGE_BY_ORIGIN = "PASSWORD_CHANGE_BY_ORIGIN"
export const PASSWORD_CHANGE_BY_ORIGIN_RESPONSE = "PASSWORD_CHANGE_BY_ORIGIN_RESPONSE"

// sms code
export const SMS_CODE_SEND = "SMS_CODE_SEND"
export const SMS_CODE_SEND_RESPONSE = "SMS_CODE_SEND_RESPONSE"
export const SMS_CODE_TIME_COUNT = "SMS_CODE_TIME_COUNT"


// user
export const USER_REGISTER = "USER_REGISTER"
export const USER_REGISTER_RESPONSE = "USER_REGISTER_RESPONSE"

