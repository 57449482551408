import urls from "../constants/urls"

import {
  restPost,
} from "../utils/restful"

export const changeBySmsCode = (cmd) => {
  return restPost({
    url: urls.passwordChangeBySmsCode,
    cmd,
  })
}

export const changeByOrigin = (cmd) => {
  return restPost({
    url: urls.passwordChangeByOrigin,
    cmd,
  })
}