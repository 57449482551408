
const BASE = ""
const API_BASE = `${BASE}/api`
const API_V1_BASE = `${API_BASE}/v1`
const OAPI_BASE = `${BASE}/oapi`
const OAPI_V1_BASE = `${OAPI_BASE}/v1`

export const HOME_URL = '/'
export const LOGIN_URL = '/login'
export const LOGOUT_URL = '/login'

export default {


  // avatarUrlPrefix
  avatarUrlPrefix: '//uploaded.changliutong.com/retail/avatar',
  defaultAvatarUrl: '//static.changliutong.com/images/default-avatar.100.png',

  // captcha
  captcha: `${BASE}/images/kaptcha.jpg`,

  // oapi
  login: `${OAPI_V1_BASE}/login`,
  passwordChangeBySmsCode: `${OAPI_V1_BASE}/password/change-by-sms-code`,
  smsCodeSend: `${OAPI_V1_BASE}/sms-code/send`,
  userRegister: `${OAPI_V1_BASE}/register`,

  // api
  authSecurityUser: `${API_V1_BASE}/auth/security-user`,
  meQuery: `${API_V1_BASE}/me/query`,
  meUpdate: `${API_V1_BASE}/me/update`,
  passwordChangeByOrigin: `${API_V1_BASE}/password/change-by-origin-password`,

  
}