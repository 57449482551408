export const restGet = (url) => {
  console.log(url)
  const params = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, params).then(res => res.json())
}

export const restPost = ({
  url,
  cmd,
}) => {
  console.log(url)
  const params = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cmd),
  }
  return fetch(url, params).then(res => {
    return res.json()
  })
}

export const upload = ({
  url,
  cmd,
}) => {
  console.log(url)
  const formData = new FormData();
  if (cmd) {
    for (const x in cmd) {
      if (Object.prototype.hasOwnProperty.call(cmd, x) && cmd[x]){
        formData.append(x, cmd[x])
      }
    }
  }
  const params = {
    method: 'POST',
    credentials: 'include',
    body: formData,
  }
  return fetch(url, params).then(res => {
    return res.json()
  })
}