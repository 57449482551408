import {
  PASSWORD_CHANGE_BY_SMS_CODE_RESPONSE,
  PASSWORD_CHANGE_BY_ORIGIN_RESPONSE,
} from '../constants/action-types'

const initialState = {
  isLoading: false,
  payload: null,
}

const passwordReducer = (state = initialState, action) => {

  const {
    payload,
  } = action
  switch (action.type) {
    case PASSWORD_CHANGE_BY_SMS_CODE_RESPONSE:
      {
        return {
          ...state,
          isLoading: false,
          payload,
        }
      }
    case PASSWORD_CHANGE_BY_ORIGIN_RESPONSE:
      {
        return {
          ...state,
          isLoading: false,
          payload,
        }
      }
    default:
      {
        return state
      }
  }
}

export default passwordReducer